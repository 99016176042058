import oidc from '@/vue-oidc-client';
import PermissionsEnum from '@/ship/Enums/PermissionsEnum';

const PMMain = () => import('@/pages/ProjectManagementPage.vue');
const PMProjects = () => import('@/pages/ProjectManagement/PMProjects.vue');
const PMProject = () => import('@/pages/ProjectManagement/PMProject.vue');
const PMCharts = () => import('@/pages/ProjectManagement/PMCharts.vue');
const PMChart = () => import('@/pages/ProjectManagement/PMChart.vue');
const PMChartBpcPage = () => import('@/pages/ProjectManagement/PMChartBpcPage.vue');
const PMChartDpcQuartPage = () => import('@/pages/ProjectManagement/PMChartDpcQuartPage.vue');
const PMChartDpcExecPage = () => import('@/pages/ProjectManagement/PMChartDpcExecPage.vue');
const PMChartWeeklyReportPage = () => import('@/pages/ProjectManagement/PMChartWeeklyReportPage.vue');
const PMChartDeadlinesPage = () => import('@/pages/ProjectManagement/PMChartDeadlinesPage.vue');
const PMChartCreateEdit = () => import('@/pages/ProjectManagement/PMChartCreateEdit.vue');
const PMReports = () => import('@/pages/ProjectManagement/PMReports.vue');
const PMReportBpc = () => import('@/pages/ProjectManagement/PMReportBpc.vue');
const PMReportDpcExec = () => import('@/pages/ProjectManagement/PMReportDpcExec.vue');
const PMReportConstruction = () => import('@/pages/ProjectManagement/PMReportConstruction.vue');
const PMReportDeadlines = () => import('@/pages/ProjectManagement/PMReportDeadlines.vue');
const PMReportConsolidated = () => import('@/pages/ProjectManagement/PMReportConsolidated.vue');
const Download = () => import('@/pages/ProjectManagement/Download.vue');

const permissions: PermissionsEnum[] = [];

export default [
    {
        name: 'projectManagement',
        path: '/project-management',
        component: PMMain,
        meta: {
            authName: oidc.authName,
            permissions,
        },
        children: [
            {
                name: 'projectManagement.projects',
                path: 'projects',
                component: PMProjects,
                meta: { authName: oidc.authName, showSidebar: true, permissions },
            },
            {
                name: 'projectManagement.charts',
                path: 'charts',
                component: PMCharts,
                meta: { authName: oidc.authName, showSidebar: true, permissions },
            },
            {
                name: 'projectManagement.charts.create',
                path: 'charts/create',
                component: PMChartCreateEdit,
                meta: { authName: oidc.authName, showSidebar: true, permissions },
            },
            {
                name: 'projectManagement.charts.edit',
                path: 'charts/edit/:id',
                component: PMChartCreateEdit,
                meta: { authName: oidc.authName, showSidebar: true, permissions },
            },
            {
                name: 'projectManagement.reports',
                path: 'reports',
                component: PMReports,
                meta: { authName: oidc.authName, showSidebar: true, permissions },
            },
            {
                name: 'projectManagement.reports.documents.bpc',
                path: 'reports/documents/bpc',
                component: PMReportBpc,
                meta: { authName: oidc.authName, showSidebar: true, permissions },
            },
            {
                name: 'projectManagement.project',
                path: 'project/:id',
                component: PMProject,
                meta: { authName: oidc.authName, showSidebar: false, permissions },
            },
            {
                name: 'projectManagement.chart',
                path: 'chart/:id',
                component: PMChart,
                meta: { authName: oidc.authName, showSidebar: false, permissions },
            },
            {
                name: 'projectManagement.chart.bpc.create',
                path: 'chart/:id/bpc/create',
                component: PMChartBpcPage,
                meta: {
                    authName: oidc.authName,
                    showSidebar: false,
                    permissions,
                    mode: 'create',
                },
            },
            {
                name: 'projectManagement.chart.bpc',
                path: 'chart/:id/bpc/:docId',
                component: PMChartBpcPage,
                meta: {
                    authName: oidc.authName,
                    showSidebar: false,
                    permissions,
                    mode: 'view',
                },
            },
            {
                name: 'projectManagement.chart.dpcQuart.create',
                path: 'chart/:id/dpc-quart/create',
                component: PMChartDpcQuartPage,
                meta: {
                    authName: oidc.authName,
                    showSidebar: false,
                    mode: 'create',
                    permissions,
                },
            },
            {
                name: 'projectManagement.chart.dpcQuart',
                path: 'chart/:id/dpc-quart/:docId',
                component: PMChartDpcQuartPage,
                meta: {
                    authName: oidc.authName,
                    showSidebar: false,
                    mode: 'view',
                    permissions,
                },
            },
            {
                name: 'projectManagement.chart.dpcExec.create',
                path: 'chart/:id/dpc-exec/create',
                component: PMChartDpcExecPage,
                meta: {
                    authName: oidc.authName,
                    showSidebar: false,
                    mode: 'create',
                    permissions,
                },
            },
            {
                name: 'projectManagement.chart.dpcExec',
                path: 'chart/:id/dpc-exec/:docId',
                component: PMChartDpcExecPage,
                meta: {
                    authName: oidc.authName,
                    showSidebar: false,
                    mode: 'view',
                    permissions,
                },
            },
            {
                name: 'projectManagement.chart.weeklyReport',
                path: 'chart/:id/weekly-report',
                component: PMChartWeeklyReportPage,
                meta: { authName: oidc.authName, showSidebar: false, permissions },
            },
            {
                name: 'projectManagement.chart.deadlines',
                path: 'chart/:id/deadlines',
                component: PMChartDeadlinesPage,
                meta: { authName: oidc.authName, showSidebar: false, permissions },
            },
            {
                name: 'projectManagement.report.dpcExec',
                path: 'report/dpc-exec',
                component: PMReportDpcExec,
                meta: { authName: oidc.authName, showSidebar: false, permissions },
            },
            {
                name: 'projectManagement.report.construction',
                path: 'report/construction',
                component: PMReportConstruction,
                meta: { authName: oidc.authName, showSidebar: false, permissions },
            },
            {
                name: 'projectManagement.report.deadlines',
                path: 'report/deadlines',
                component: PMChartDeadlinesPage,
                meta: { authName: oidc.authName, showSidebar: false, permissions },
            },
            {
                name: 'projectManagement.report.consolidated',
                path: 'report/consolidated',
                component: PMReportConsolidated,
                meta: { authName: oidc.authName, showSidebar: false, permissions },
            },
        ],
    },
    {
        name: 'projectManagement.file.download',
        path: '/project-management/file/:type/:id/download',
        component: Download,
        meta: { authName: oidc.authName, showSidebar: false, permissions },
    },
];
