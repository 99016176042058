import { GetterTree } from 'vuex';
import naturalSort from 'javascript-natural-sort';
import { get } from 'lodash';

import UserService from '@/utils/UserService';

// Interfaces
import City from '@/ship/Models/City';
import Project from '@/ship/Models/Project';
import Organisation from '@/ship/Models/Organisation';
import { RootState } from '@/store/Root/Root';

// Enums
import OrganisationService from '@/ship/Services/OrganisationService';
import { IPMChartVersionRow } from '@/ship/Models/IProjectManagement';

export const getters: GetterTree<RootState, RootState> = {
    userCities(state: RootState): City[] {
        return get(state.user, 'cities.data', []);
    },

    userProjects(state: RootState): Project[] {
        return get(state.user, 'projects.data', []);
    },

    sortedRequirements(state: RootState) {
        const requirements = state.requirements ? state.requirements.slice() : [];
        return requirements.sort((a, b) => naturalSort(a.name, b.name));
    },

    generalContractors(state: RootState): Organisation[] {
        return state.organisations?.filter(OrganisationService.isGeneralContractor) ?? [];
    },

    generalDesigners(state: RootState): Organisation[] {
        return state.organisations?.filter(OrganisationService.isGeneralDesigner) ?? [];
    },

    subcontractors(state: RootState): Organisation[] {
        return state.organisations?.filter(OrganisationService.isSubcontractor) ?? [];
    },

    customers(state: RootState): Organisation[] {
        return state.organisations?.filter(OrganisationService.isCustomer) ?? [];
    },

    designCustomers(state: RootState): Organisation[] {
        return state.organisations?.filter(OrganisationService.isDesignCustomer) ?? [];
    },

    isUserAdmin(state: RootState): boolean {
        return state.user ? UserService.isAdmin(state.user) : false;
    },

    isUserCustomer(state: RootState): boolean {
        return state.user ? UserService.isCustomer(state.user) : false;
    },

    isUserGeneralContractor(state: RootState): boolean {
        return state.user ? UserService.isGeneralContractor(state.user) : false;
    },

    chartVersions(state: RootState): IPMChartVersionRow[] {
        return state.chart ? [...state.chart.versions!.data].map((version) => ({ ...version, selected: false })) : [];
    },
};
export default getters;
